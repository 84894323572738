import styled from "styled-components";
import { EndSequence } from "./App";
import { useMemo } from "react";

const Wrapper = styled.div`
  position: absolute;
  border-radius: 20px;
  top: 20%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 1150px;
  width: 80vw;
  height: 475px;
  padding: 20px;
  padding-top: 0px;
  background-color: white;
  color: #49323e;
  border-style: solid;
  border-color: #49323e;
  border-width: 0.5px;
  font-family: "Mulish";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    height: 80%;
    top: 8%;
  }
`;

const Header = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  line-height: 50px;
  color: #49323e;

  @media (max-width: 768px) {
    font-family: Mulish;
    font-size: 21px;
    font-weight: 400;
    line-height: 30px;
    text-align: center;
  }
`;

const Logo = styled.div`
  width: 140px;
  @media (max-width: 768px) {
    visibility: hidden;
  }
  align-self: flex-start;
  margin-bottom: 20px;
`;

const TableViewItem = styled.div`
  /* width: 303px; */
  height: 288px;
  padding: 14px 14px 20px 14px;
  gap: 14px;
  display: flex;
  flex-direction: column;
  border-radius: 10px 0px 0px 0px;
  max-width: 250px;
  width: 20vw;
  /* border: 1.5px 0px 0px 0px;
  opacity: 0px; */
  @media (max-width: 768px) {
    flex: 1 0 29%;
    height: auto;
    max-width: 150px;
    /* order: -1; */
    /* width: min-content; */
  }
`;

const TableView = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media (max-width: 768px) {
    justify-content: center;
    /* flex-direction: column; */
    /* gap: 10px; */
  }
`;

const TableText = styled.p`
  //styleName: Body Header;
  font-family: Mulish;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  margin: 0px;
`;

const TableImage = styled.img`
  border-radius: 10px;
  height: 200px;
  object-fit: cover;
  max-width: 250px;
  width: 100%;
  @media (max-width: 768px) {
    height: 150px;
    max-width: 150px;
  }
`;

export const ViewMore = ({
  endSequence,
}: {
  endSequence: EndSequence | undefined;
}) => {
  const options = useMemo(() => {
    return endSequence?.viewMore.options.map((option, key) => {
      return (
        <TableViewItem
          key={key}
          /* className="table-view-item"
          style={{
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            // height: "220px",
            // width: "100%",
            // borderRadius: "10px",
            // // backgroundColor: "#49323e",
            // color: "white",
            // fontSize: "20px",
            // fontWeight: 600,
            maxWidth: "300px",
          }} */
        >
          <a href={option.pageUrl}>
            <TableImage
              src={option.posterUrl}
              alt="Social Anxienty poster"
              // width={"180px"}
            />
          </a>
          <TableText>{option.description || ""}</TableText>
        </TableViewItem>
      );
    });
  }, [endSequence?.viewMore.options]);

  return (
    <Wrapper>
      <Logo>
        <img
          src="./logo.png"
          alt="oVRcome"
          style={{ maxHeight: "100%", maxWidth: "100%" }}
        ></img>
      </Logo>
      <Header>
        {endSequence?.viewMore.headerLine || "Are you ready for a new reality?"}
      </Header>
      <TableView>{options}</TableView>
      {/* <p>I'll do this later</p> */}
    </Wrapper>
  );
};
