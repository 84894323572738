import React, { useEffect, useMemo, useState } from "react";
import "./App.css";
import { Canvas } from "@react-three/fiber";
import { DefaultLoadingManager, RepeatWrapping, VideoTexture } from "three";
import { requestMotionPermission } from "./motionPermission";
import axios from "axios";
import styled from "styled-components";
import { CanvasContent } from "./CanvasContent";
import { ViewMore } from "./ViewMore";

export type EndSequence = {
  type: "email" | "viewMore";
  linkoutUrl: string;
  endTime: number;
  viewMore: {
    headerLine: string;
    options: [{ posterUrl: string; pageUrl: string; description: string }];
  };
};

type Product = {
  videoUrl: string;
  endSequence: EndSequence;
  headerLine: string;
  groupId: string;
};

const Wrapper = styled.div`
  position: absolute;
  border-radius: 20px;
  top: 20%;
  z-index: 10;
  left: 50%;
  transform: translate(-50%, 0);
  max-width: 330px;
  width: 80vw;
  height: 475px;
  padding: 20px;
  background-color: white;
  color: #49323e;
  border-style: solid;
  border-color: #49323e;
  border-width: 0.5px;
  font-family: "Mulish";
`;

const Header = styled.h1`
  font-size: 28px;
  font-weight: 600;
  text-align: center;
  margin: 0px;
  line-height: 38px;
`;

const SubHeader = styled.h2`
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
  margin: 10px;
`;

const EmailText = styled.p`
  font-family: Mulish;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
`;

const Button = styled.button`
  width: 320px;
  height: 72px;
  gap: 15.09px;
  border-radius: 8px 0px 0px 0px;
  background: #621b4d;
  color: #ffffff;
  border: none;

  font-family: Mulish;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
`;

function App() {
  const [start, setStart] = useState(false);
  const [signUp, setSignUp] = useState(false);
  const [viewMore, setViewMore] = useState(false);
  const [motionPerm, setMotionPerm] = useState({
    haveSensors: false,
    needPermissions: false,
  });
  const [product, setProduct] = useState<Product | undefined>(undefined);
  const [url, setUrl] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState("");
  const [intro, setIntro] = useState(true);

  useEffect(() => {
    const queryString = window.location.search;
    console.log(queryString);
    const urlParams = new URLSearchParams(queryString);
    const productUrl = urlParams.get("p");

    const loadFile = async () => {
      try {
        const response = await axios.get(
          `${process.env.PUBLIC_URL}/p/${productUrl as string}`
        );
        console.log("response", response.data);
        setProduct((response.data as any as Product) || undefined);
      } catch (e) {
        console.error(e);
        setProduct(undefined);
      }
    };
    loadFile();
  }, [setProduct]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIntro(false);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    if (product) {
      setUrl(product.videoUrl);
    }
  }, [product]);

  const video = useVideo({
    url,
    setSignUp,
    endSequence: product?.endSequence || undefined,
    setViewMore: setViewMore,
  });

  // const requestMotionPerm = useCallback(async (e?: any) => {
  //   const perm = await requestMotionPermission();
  //   console.log("perm", perm);
  //   setMotionPerm(perm);
  // }, []);

  // useEffect(() => {
  //   requestMotionPerm();
  // }, [requestMotionPerm]);

  // useEffect(() => {
  //   // setStart(motionPerm);
  //   if (video) {
  //     video.play();
  //   }
  // }, [video]);

  const texture = useMemo(() => {
    if (!video) return null;
    console.log("video", video);
    const texture = new VideoTexture(video);
    texture.wrapS = RepeatWrapping;
    texture.repeat.x = -1;
    return texture;
  }, [video]);

  return (
    <>
      {signUp && (
        <Wrapper>
          <Header>
            {product?.headerLine || "Are you ready for a new reality?"}
          </Header>
          <SubHeader>
            oVRcome is the clinically proven way to get anxiety, stress and fear
            out of your life
          </SubHeader>
          <div
            style={{
              outline: "1px solid #49323e",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
              padding: "5px",
              paddingRight: "0px",
              paddingLeft: "0px",
            }}
          >
            <EmailText style={{ margin: "0px", marginLeft: "5px" }}>
              Email address
            </EmailText>
            <input
              type="text"
              autoFocus
              style={{}}
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <Button
            style={{
              marginTop: "20px",
              width: "100%",
              height: "50px",
              borderRadius: "10px",
            }}
            onClick={async () => {
              try {
                await axios.post(
                  "https://sqkippwnsl.execute-api.ap-southeast-2.amazonaws.com",
                  {
                    email,
                    groupId: product ? product.groupId : "undefined",
                  }
                );
              } catch (e) {
                console.error(e);
              } finally {
                setSignUp(false);
                window.open(product?.endSequence.linkoutUrl, "_self");
              }
            }}
          >
            Sign me up
          </Button>
          {/* <p>I'll do this later</p> */}
        </Wrapper>
      )}

      {viewMore && <ViewMore endSequence={product?.endSequence} />}

      <div id="canvas-container" style={{ width: "100vw", height: "100vh" }}>
        {intro && (
          <div
            style={{
              position: "absolute",
              zIndex: 1,
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            <img src="./instruction.png" alt="instruction" width="146px" />
          </div>
        )}
        {!intro && (
          <button
            style={{
              position: "absolute",
              zIndex: 1,
              visibility: !start && !signUp ? "visible" : "hidden",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "none",
              background: "none",
            }}
            onClick={(e) => {
              // requestMotionPerm(e);
              requestMotionPermission(e).then((sensor) => {
                setStart(true);
                if (video) {
                  video.muted = false;

                  console.log("PERM", video.currentTime, sensor);

                  video.play();
                  setMotionPerm(sensor);
                }
              });
            }}
          >
            <img src="./play.svg" alt="play"></img>
          </button>
        )}

        <Canvas>
          <CanvasContent
            motionPerm={motionPerm}
            texture={texture}
            playing={start}
          />
        </Canvas>
      </div>
    </>
  );
}

// function VideoMaterial({
//   url,
//   start,
//   muted,
// }: {
//   url: string;
//   start: boolean;
//   muted: boolean;
// }) {
//   console.log("muted", muted, "start", start);
//   const texture = useVideoTexture(url, {
//     crossOrigin: "anonymous",
//     loop: false,
//     start: start,
//     volume: 1,
//     muted: muted,
//   });
//   return <meshBasicMaterial map={texture} toneMapped={false} side={BackSide} />;
// }

// function FallbackMaterial({ url }: { url: string }) {
//   const texture = useTexture(url);
//   return <meshBasicMaterial map={texture} toneMapped={false} />;
// }

export default App;

export const useVideo = ({
  url,
  setSignUp,
  endSequence,
  setViewMore,
}: {
  url: string | undefined;
  setSignUp: React.Dispatch<React.SetStateAction<boolean>>;
  endSequence: EndSequence | undefined;
  setViewMore: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const video = useMemo<HTMLVideoElement | null>(() => {
    if (!endSequence) return null;
    console.log("url", url);
    if (!url) return null;
    const videoElement = document.createElement("video");

    const loadingManager = DefaultLoadingManager;

    videoElement.src = url;
    loadingManager.itemStart(url);
    videoElement.muted = true;
    videoElement.playsInline = true;
    videoElement.autoplay = false;
    videoElement.preload = "auto";
    videoElement.crossOrigin = "anonymous";

    const onVideoLoad = () => {
      videoElement.removeEventListener("canplaythrough", onVideoLoad, false);
      videoElement.removeEventListener("error", onVideoError, false);
      loadingManager.itemEnd(url);
    };

    const onVideoEnded = () => {
      videoElement.removeEventListener("ended", onVideoLoad, false);
      videoElement.removeEventListener("error", onVideoError, false);
      videoElement.removeEventListener("canplaythrough", onVideoError, false);
      if (endSequence.type === "email") {
        setSignUp(true);
      } else if (endSequence.type === "viewMore") {
        window.open(endSequence.linkoutUrl, "_blank");
        setViewMore(true);
      }
    };

    const onVideoError = () => {
      videoElement.removeEventListener("canplaythrough", onVideoLoad, false);
      videoElement.removeEventListener("error", onVideoError, false);

      loadingManager.itemEnd(url);
      loadingManager.itemError(url);
    };

    const onVideoProgress = (event: any) => {
      // console.log("progress", event);
    };

    const onVideoTimeUpdate = (event: any) => {
      // console.log(
      //   "sign up",
      //   event.target,
      //   endSequence,
      //   videoElement,
      //   videoElement.currentTime
      // );
      if (
        endSequence.endTime &&
        videoElement.currentTime * 1000 >= endSequence.endTime
      ) {
        console.log(
          "sign up",
          event.target,
          endSequence.endTime,
          videoElement,
          videoElement.currentTime
        );
        if (endSequence.type === "email") {
          setSignUp(true);
        } else if (endSequence.type === "viewMore") {
          setViewMore(true);
        }
        videoElement.pause();
      }
    };

    videoElement.addEventListener("canplaythrough", onVideoLoad, false);
    videoElement.addEventListener("error", onVideoError, false);
    videoElement.addEventListener("progress", onVideoProgress, false);
    videoElement.removeEventListener("ended", onVideoEnded, false);

    videoElement.addEventListener("ended", onVideoEnded, false);
    videoElement.removeEventListener("timeupdate", onVideoTimeUpdate, false);

    videoElement.addEventListener("timeupdate", onVideoTimeUpdate, false);

    // This is needed to cause the canplaythrough event to fire on iOS
    videoElement.load();
    return videoElement;
  }, [endSequence, url, setSignUp, setViewMore]);

  // return <video ref={videoElementRef}>Video {video.name}</video>;
  return video;
};
